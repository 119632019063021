import { Link, useFetcher } from "@remix-run/react"
import { cn } from "~/utils/shadecn"
import { Avatar, AvatarFallback, AvatarImage } from "~/components/ui/avatar"
import { useCallback, useEffect, useRef, useState } from "react"
import { Skeleton } from "~/components/ui/skeleton"
import videoDefault from "~/assets/videos/default.webp"
import Typography from "~/components/typography"
import { Button } from "~/components/ui/button"
import dayjs from "dayjs"
import useIsVisible from "~/utils/is-visible"
import showdown from "showdown"
import { Separator } from "~/components/ui/separator"
import { type loader as videoLoader } from "~/routes/_app+/videos+/$videoID.info"
import { BookmarkButton } from "~/components/bookmarks"
import { LikeButton } from "~/components/likes"
import moment from "moment"

export const ImgContainer = ({ href, aspect, children }) => {
  return (
    <>
      {href ?
        <Link
          to={href}
          className={`relative block aspect-${aspect} w-full rounded-sm overflow-clip shadow-sm bg-background hover:shadow`}>
          {children}
        </Link>
      : <div
          className={`relative block aspect-${aspect} w-full rounded-sm overflow-clip shadow-sm bg-background hover:shadow`}>
          {children}
        </div>
      }
    </>
  )
}

export const ImageCard = ({ href, img, alt, avatar, overlay, aspect, rentedAt, className, style }: any) => {
  const [load, setLoad] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const image = useRef(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const isVisible = useIsVisible(containerRef)

  aspect = aspect || "video"

  const handleLoad = useCallback(() => {
    setLoaded(true)
  }, [])

  useEffect(() => {
    if (image.current?.complete) {
      setLoaded(true)
    }
  }, [image.current])

  useEffect(() => {
    if (isVisible && !load) {
      setLoad(true)
    }
  }, [isVisible])

  return (
    <div
      ref={containerRef}
      className={cn(`slide relative aspect-${aspect} box-border group embla__slide p-1`, className)}
      style={style}>
      <ImgContainer href={href} aspect={aspect}>
        {!loaded && <Skeleton className={`absolute top-0 left-0 aspect-${aspect} block w-full`} />}
        {isVisible && (
          <img
            ref={image}
            loading="lazy"
            onLoad={handleLoad}
            className={cn(
              `embla__slide__img aspect-${aspect} block w-full object-cover transition-opacity duration-500`,
              {
                "opacity-0": !loaded,
                "opacity-100": loaded,
              }
            )}
            src={img}
            alt={alt}
          />
        )}
      </ImgContainer>

      {load && avatar && (
        <div className="absolute flex justify-start items-center sm:font-medium rounded-xl gap-2 top-2 left-2 text-white bg-black/20 backdrop-blur">
          {avatar}
        </div>
      )}

      {load && overlay && (
        <div className="absolute justify-center items-center top-0 left-0 w-full h-full hidden group-hover:flex">
          {overlay}
        </div>
      )}

      {rentedAt && dayjs(rentedAt).diff(dayjs().add(-1, "month"), "day") < 0 && (
        <div className="absolute rounded-sm top-1 left-1 right-1 bottom-0 bg-zinc-900/60">
          <Link to={href} className="block w-full h-full flex justify-center items-center">
            <Button
              variant="secondary"
              className="text-muted-foreground group-hover:bg-primary group-hover:text-primary-foreground group-hover:shadow transition-all duration-500 delay-200">
              Rent Again
            </Button>
          </Link>
        </div>
      )}
    </div>
  )
}

export const VideoCard = ({ loggedIn, video, source, sourceID, rentedAt, className, style, viewOnly }: any) => {
  const fetcher = useFetcher<typeof videoLoader>()
  const [videoData, setVideoData] = useState(video)
  const [fetched, setFetched] = useState(false)

  const onHover = () => {
    if (fetched || fetcher.state !== "idle") {
      return
    }

    fetcher.submit(new FormData(), {
      action: `/videos/${video.VideoID}/info`,
      method: "GET",
    })

    setFetched(true)
  }

  useEffect(() => {
    if (fetcher.data) {
      setVideoData(fetcher.data.video)
    }
  }, [fetcher.data])

  useEffect(() => {
    setVideoData(video)
    setFetched(false)
  }, [video])

  return (
    <div
      onMouseEnter={onHover}
      className={cn(`slide relative aspect-video box-border group embla__slide p-1`, className)}
      style={style}>
      <ImageCard
        href={!viewOnly && `/videos/${video.VideoID}${source ? `?s=${source}&id=${sourceID}` : ""}`}
        img={(!loggedIn && video.Visibility !== 0 ? video.BlurredStill : video.Stills[0]) || videoDefault}
        alt={video.VideoTitle}
        rentedAt={rentedAt}
        style={style}
        avatar={
          <>
            <Avatar className="h-4 w-4 sm:h-5 sm:w-5">
              <AvatarImage src={video.StarAvatar} className="object-cover" alt="avatar" />
            </Avatar>
            <div className="text-[0.5rem] sm:text-[0.6rem] md:text-xs mr-2">{video.StarDisplayName}</div>
          </>
        }
        overlay={
          !loggedIn && video.Visibility !== 0 ?
            <div className="text-[0.5rem] sm:text-[0.6rem] md:text-xs bg-black/50 p-1 m-4 rounded-lg text-center text-white">
              <p className="mb-2">
                This MakeLoveNotPornstar has chosen to only make their videos discoverable by the community.
              </p>
              <p>
                <Link to={`/register`} className="block mb-1">
                  <Button className="h-6 px-2 py-1 text-xs">Join Here</Button>
                </Link>
              </p>
            </div>
          : undefined
        }
      />
      <div className="box-border md:invisible md:absolute md:bottom-2 md:-z-10 -mt-1 md:m-2 pt-0 sm:pt-1 px-2 md:pt-4 md:px-4 border bg-accent text-accent-foreground left-0 right-0 rounded-b-lg md:transition-transform md:ease-in-out md:duration-500 md:delay-200 md:group-hover:visible md:group-hover:block md:group-hover:translate-y-full">
        {<VideoDescription video={videoData} />}
      </div>
    </div>
  )
}

export const VideoDescription = ({ video, className, style }: any) => {
  return (
    <div className={className} style={style}>
      <h4 className="line-clamp-2 mt-[0.5rem] sm:mt-1 mb-1 sm:mb-2 text-sm sm:text-xs font-semibold">
        {video.VideoTitle}
      </h4>
      <div className="hidden md:block">
        <p className="line-clamp-4 mb-1 sm:mb-2 text-xs">{video.VideoDescription}</p>
      </div>
      <div className="flex justify-start gap-1">
        {(video.Tags?.length > 0 || video.MarqueeTags?.length > 0) && (
          <Typography>
            <p className="mt-0 mb-1 sm:mb-2 leading-7 text-xs">
              <span>Tags: </span>
              {video.MarqueeTags?.length > 0 &&
                video.MarqueeTags.map((tag: any, index: number) => (
                  <span key={index}>
                    <Link to={`/videos/tag/${tag.Name}`}>{tag.Name}</Link>{" "}
                  </span>
                ))}
              {video.Tags?.length > 0 &&
                video.Tags.map((tag: any, index: number) => (
                  <span key={index}>
                    <Link to={`/videos/tag/${tag.Name}`}>{tag.Name}</Link>{" "}
                  </span>
                ))}
            </p>
          </Typography>
        )}
      </div>
    </div>
  )
}

export const StarCard = ({ star, className, style }: any) => {
  return (
    <ImageCard
      href={`/makelovenotpornstars/${star.UserName}`}
      avatar={<div className="text-xs mr-2 line-clamp-1">{star.DisplayName}</div>}
      img={star.Avatar}
      alt={star.DisplayName}
      aspect="square"
      className="flex-none h-48"
    />
  )
}

export const FeedCard = ({ post, className, style }: any) => {
  const converter = new showdown.Converter()

  return (
    <div>
      {post.CommentID ?
        <div
          className={cn(
            "p-2 rounded-md bg-accent/80 text-accent-foreground/80 hover:bg-accent hover:text-accent-foreground",
            post.IsLoveNote && "bg-primary/10 hover:bg-primary/20 text-center"
          )}>
          <div className="flex items-center justify-between gap-2">
            <p className="text-xs text-muted-foreground line-clamp-2 text-left">
              {post.Reply ?
                <>You've received a reply to your comment on the video </>
              : <>
                  A{" "}
                  {post.IsLoveNote ?
                    <b>Love Note</b>
                  : "comment"}{" "}
                  was left on your video{" "}
                </>
              }
              <Link className="text-primary-dark underline" to={`/videos/${post.VideoID}?s=HappyPlaceFeed&id=0`}>
                {post.VideoTitle}
              </Link>
            </p>
            {post.IsLoveNote && <div className="text-sm text-primary font-bold">${post.Amount}</div>}
          </div>
          <Separator className="my-2 -mx-2 w-auto bg-foreground/10" />
          <div className="flex justify-between items-center gap-2">
            <div className="flex items-center gap-2">
              <Avatar className="h-5 w-5">
                <AvatarImage src={post.Avatar} className="object-cover" alt="avatar" />
                <AvatarFallback>JA</AvatarFallback>
              </Avatar>
              <Typography>
                <h4 className="font-medium text-[12px] sm:text-[14px] tracking-wide">
                  {post.UserName && post.UserPrivate !== 1 && !post.IsAnonymous ?
                    <Link to={`/makelovenotpornstars/${post.UserName}`}>{post.DisplayName}</Link>
                  : <span>{post.IsAnonymous ? "Secret Admirer" : post.DisplayName}</span>}
                </h4>
              </Typography>
              <p className="text-muted-foreground text-[9px] sm:text-xs">
                {moment(post.CreatedAt!.toString()).fromNow()}
              </p>
            </div>
          </div>
          <div className="mx-6">
            {post.HasLoveObject && post.LoveObjectSrc && (
              <div className="flex justify-center sm:mx-6">
                <div className="relative">
                  <img src={post.LoveObjectSrc} alt="Love Object" className="w-14 h-14 sm:w-24 sm:h-24" />
                </div>
              </div>
            )}
            <Typography
              className={cn("prose-sm first:prose-p:mt-1 last:prose-p:mb-1", {
                "text-primary": post.IsLoveNote,
              })}>
              {post.Deleted ?
                <p className="italic text-muted-foreground">This user has deleted their comment</p>
              : <div
                  dangerouslySetInnerHTML={{
                    __html: converter.makeHtml(post.Content?.replace(/(?:\r\n|\r|\n)/g, "  \r\n") || ""),
                  }}
                />
              }
            </Typography>
          </div>
        </div>
      : <>
          {post.VideoID ?
            <>
              <ImageCard
                href={`/videos/${post.VideoID}?s=HappyPlaceFeed&id=0`}
                img={post.Stills[0] || videoDefault}
                alt={post.VideoTitle}
                className={cn("p-0", className)}
                style={style}
                avatar={
                  <>
                    <Avatar className="h-4 w-4 sm:h-5 sm:w-5">
                      <AvatarImage src={post.StarAvatar} className="object-cover" alt="avatar" />
                    </Avatar>
                    <div className="text-[0.5rem] sm:text-[0.6rem] md:text-xs mr-2">{post.StarDisplayName}</div>
                  </>
                }
              />
              <div className="box-border border bg-accent text-accent-foreground rounded-b-lg -mt-1 pt-2 pb-4 px-4">
                <h4 className="line-clamp-2 mt-[0.5rem] sm:mt-1 mb-1 sm:mb-2 text-sm sm:text-lg font-semibold">
                  {post.VideoTitle}
                </h4>
                <div>
                  <p className="line-clamp-4 mb-1 text-xs">{post.VideoDescription}</p>
                </div>
                <div className="flex justify-between gap-1">
                  <Typography>
                    {(post.Tags?.length > 0 || post.MarqueeTags?.length > 0) && (
                      <p className="mt-0 mb-1 sm:mb-2 leading-7 text-xs">
                        <span>Tags: </span>
                        {post.MarqueeTags?.length > 0 &&
                          post.MarqueeTags.map((tag: any, index: number) => (
                            <span key={index}>
                              <Link to={`/videos/tag/${tag.Name}`}>{tag.Name}</Link>{" "}
                            </span>
                          ))}
                        {post.Tags?.length > 0 &&
                          post.Tags.map((tag: any, index: number) => (
                            <span key={index}>
                              <Link to={`/videos/tag/${tag.Name}`}>{tag.Name}</Link>{" "}
                            </span>
                          ))}
                      </p>
                    )}
                  </Typography>
                  <Typography className="text-xs text-muted-foreground mt-1">
                    <p>
                      Posted on:{" "}
                      {dayjs(post.PublishedAt ? post.PublishedAt : post.CreatedAt).format("MM/DD/YYYY hh:mm A")}
                    </p>
                  </Typography>
                </div>
                <Separator className="my-4 -mx-4 w-auto bg-foreground/40" />
                <div className="flex flex-wrap justify-between items-center gap-2">
                  <div>
                    <LikeButton videoID={post.VideoID} likeCount={post.likeCount} likeID={post.likeID} />
                  </div>
                  <div>
                    <BookmarkButton videoID={post.VideoID} bookmarkID={post.bookmarkID} />
                  </div>
                  <div>
                    <Link to={`/videos/${post.VideoID}?s=HappyPlaceFeed&id=0`}>
                      <Button>View Video</Button>
                    </Link>
                  </div>
                </div>
              </div>
            </>
          : <>
              {post.SocialPostID ?
                <>
                  <ImageCard
                    img={post.Image}
                    alt={post.Title}
                    className={cn("p-0", className)}
                    style={style}
                    avatar={
                      <>
                        <Avatar className="h-4 w-4 sm:h-5 sm:w-5">
                          <AvatarImage src={post.StarAvatar} className="object-cover" alt="avatar" />
                        </Avatar>
                        <div className="text-[0.5rem] sm:text-[0.6rem] md:text-xs mr-2">{post.StarDisplayName}</div>
                      </>
                    }
                  />
                  <div className="box-border border bg-accent text-accent-foreground rounded-b-lg -mt-1 py-2 px-4">
                    <h4 className="line-clamp-2 mt-[0.5rem] sm:mt-1 mb-1 sm:mb-2 text-sm sm:text-lg font-semibold">
                      {post.Title}
                    </h4>
                    <div>
                      <p
                        className="line-clamp-4 mb-1 text-xs"
                        dangerouslySetInnerHTML={{
                          __html: converter.makeHtml(post.Comment || ""),
                        }}></p>
                    </div>
                    <div className="flex justify-between gap-1">
                      <Typography></Typography>
                      <Typography className="text-xs text-muted-foreground">
                        <p>
                          Posted on:{" "}
                          {dayjs(post.PublishedAt ? post.PublishedAt : post.CreatedAt).format("MM/DD/YYYY hh:mm A")}
                        </p>
                      </Typography>
                    </div>
                  </div>
                </>
              : <>
                  <div className="box-border border bg-accent text-accent-foreground rounded-b-lg -mt-1 pt-2 pb-4 px-4">
                    <h4 className="line-clamp-2 mt-[0.5rem] sm:mt-1 mb-1 sm:mb-2 text-sm sm:text-lg font-semibold">
                      {post.Title}
                    </h4>
                    <div>
                      <p className="line-clamp-4 mb-1 text-xs">{post.Description}</p>
                    </div>
                    <div className="flex justify-between gap-1">
                      <Typography className="text-xs text-muted-foreground mt-1">
                        <p>
                          Posted on:{" "}
                          {dayjs(post.PublishedAt ? post.PublishedAt : post.CreatedAt).format("MM/DD/YYYY hh:mm A")}
                        </p>
                      </Typography>
                    </div>
                    <Separator className="my-4 -mx-4 w-auto bg-foreground/40" />
                    <div className="flex flex-wrap justify-between items-center gap-2">
                      <div>
                        <LikeButton videoID={post.VideoID} likeCount={post.likeCount} likeID={post.likeID} />
                      </div>
                      <div>
                        <BookmarkButton videoID={post.VideoID} bookmarkID={post.bookmarkID} />
                      </div>
                      <div>
                        <Link to={post.ActionLink}>
                          <Button>{post.ActionText}</Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </>
              }
            </>
          }
        </>
      }
    </div>
  )
}

export const FeedCardSmall = ({ post, className, style, open, setOpen }: any) => {
  const converter = new showdown.Converter()

  return (
    <div className="relative">
      {post.CommentID ?
        <div
          className={cn(
            "p-2 rounded-md bg-accent/80 text-accent-foreground/80 hover:bg-accent hover:text-accent-foreground",
            post.IsLoveNote && "bg-primary/10 hover:bg-primary/20 text-center"
          )}>
          <div className="flex items-center justify-between gap-2">
            <div className="text-xs text-muted-foreground text-left">
              <p className="mb-0">
                {post.Reply ?
                  <>You've received a reply to your comment on the video </>
                : <>
                    A{" "}
                    {post.IsLoveNote ?
                      <b>Love Note</b>
                    : "comment"}{" "}
                    was left on your video{" "}
                  </>
                }
              </p>
              <p className="line-clamp-1 mt-0">
                <Link className="text-primary-dark underline" to={`/videos/${post.VideoID}?s=Notifications&id=0`}>
                  {post.VideoTitle}
                </Link>
              </p>
            </div>
            {post.IsLoveNote && <div className="text-sm text-primary font-bold">${post.Amount}</div>}
          </div>
          <Separator className="my-2 -mx-2 w-auto bg-foreground/10" />
          <div className="flex justify-between items-center gap-2">
            <div className="flex items-center gap-2">
              <Avatar className="h-5 w-5">
                <AvatarImage src={post.Avatar} className="object-cover" alt="avatar" />
                <AvatarFallback>JA</AvatarFallback>
              </Avatar>
              <Typography>
                <h4 className="font-medium text-[12px] sm:text-[14px] tracking-wide">
                  {post.UserName && post.UserPrivate !== 1 && !post.IsAnonymous ?
                    <Link to={`/makelovenotpornstars/${post.UserName}`}>{post.DisplayName}</Link>
                  : <span>{post.IsAnonymous ? "Secret Admirer" : post.DisplayName}</span>}
                </h4>
              </Typography>
              <p className="text-muted-foreground text-[9px] sm:text-xs">
                {moment(post.CreatedAt!.toString()).fromNow()}
              </p>
            </div>
          </div>
          <div className="mx-6">
            {post.HasLoveObject && post.LoveObjectSrc && (
              <div className="flex justify-center sm:mx-6">
                <div className="relative">
                  <img src={post.LoveObjectSrc} alt="Love Object" className="w-14 h-14 sm:w-24 sm:h-24" />
                </div>
              </div>
            )}
            <Typography
              className={cn("prose-sm first:prose-p:mt-1 last:prose-p:mb-1", {
                "text-primary": post.IsLoveNote,
              })}>
              {post.Deleted ?
                <p className="italic text-muted-foreground">This user has deleted their comment</p>
              : <div
                  dangerouslySetInnerHTML={{
                    __html: converter.makeHtml(post.Content?.replace(/(?:\r\n|\r|\n)/g, "  \r\n") || ""),
                  }}
                />
              }
            </Typography>
          </div>
        </div>
      : <>
          {post.VideoID ?
            <div className="p-2 rounded-md bg-accent/80 text-accent-foreground/80 hover:bg-accent hover:text-accent-foreground">
              <div className="grid grid-cols-3 gap-4 items-center">
                <ImageCard
                  img={post.Stills[0] || videoDefault}
                  alt={post.VideoTitle}
                  className={cn("p-0", className)}
                  style={style}
                />
                <div className="col-span-2">
                  <h4 className="line-clamp-1 text-sm sm:text-lg font-semibold">{post.VideoTitle}</h4>
                  <div>
                    <p
                      className="line-clamp-2 text-xs"
                      dangerouslySetInnerHTML={{
                        __html: converter.makeHtml(post.VideoDescription || ""),
                      }}></p>
                  </div>
                </div>
              </div>
              <Separator className="my-2 -mx-2 w-auto bg-foreground/10" />
              <div className="flex justify-end items-center gap-2">
                <Typography className="text-xs">
                  <p>
                    <Link to={`/makelovenotpornstars/${post.StarName}`} onClick={() => setOpen(false)}>
                      {post.StarDisplayName}
                    </Link>
                    <span className="text-muted-foreground/60">
                      {" "}
                      - {dayjs(post.PublishedAt ? post.PublishedAt : post.CreatedAt).format("MM/DD/YYYY")}
                    </span>
                  </p>
                </Typography>
                <Link to={`/videos/${post.VideoID}?s=Notifications&id=0`} onClick={() => setOpen(false)}>
                  <Button size="sm" className="h-5">
                    View Video
                  </Button>
                </Link>
              </div>
            </div>
          : <>
              {post.SocialPostID ?
                <div className="p-2 rounded-md bg-accent/80 text-accent-foreground/80 hover:bg-accent hover:text-accent-foreground">
                  <div className="grid grid-cols-3 gap-4 items-center">
                    <ImageCard img={post.Image} alt={post.Title} className={cn("p-0", className)} style={style} />
                    <div className="col-span-2">
                      <h4 className="line-clamp-1 text-sm sm:text-lg font-semibold">{post.Title}</h4>
                      <div>
                        <p
                          className="line-clamp-2 text-xs"
                          dangerouslySetInnerHTML={{
                            __html: converter.makeHtml(post.Comment || ""),
                          }}></p>
                      </div>
                    </div>
                  </div>
                  <Separator className="my-2 -mx-2 w-auto bg-foreground/10" />
                  <div className="flex justify-end items-center gap-2">
                    <Typography className="text-xs">
                      <p>
                        <Link to={`/makelovenotpornstars/${post.StarName}`} onClick={() => setOpen(false)}>
                          {post.StarDisplayName}
                        </Link>
                        <span className="text-muted-foreground/60">
                          {" "}
                          - {dayjs(post.PublishedAt ? post.PublishedAt : post.CreatedAt).format("MM/DD/YYYY")}
                        </span>
                      </p>
                    </Typography>
                    <Link to={`/makelovenotpornstars/${post.StarName}/social`} onClick={() => setOpen(false)}>
                      <Button size="sm" className="h-5">
                        View Posts
                      </Button>
                    </Link>
                  </div>
                </div>
              : <div className="box-border border border-destructive/50 text-destructive dark:border-destructive text-center bg-destructive/10 rounded-lg -mt-1 pt-2 pb-4 px-4">
                  <h4 className="line-clamp-2 mt-[0.5rem] sm:mt-1 mb-1 sm:mb-2 text-sm sm:text-lg font-semibold">
                    {post.Title}
                  </h4>
                  <div>
                    <p className="line-clamp-4 mb-1 text-sm">{post.Description}</p>
                  </div>
                  <Separator className="my-4 -mx-4 w-auto bg-foreground/40" />
                  <Link className="block" onClick={() => setOpen(false)} to={post.ActionLink}>
                    <Button className="block w-full">{post.ActionText}</Button>
                  </Link>
                </div>
              }
            </>
          }
          {post.New && (
            <div className="absolute top-1 right-1 bg-warning text-warning-foreground rounded-md p-[2px] text-[0.6rem] font-semibold">
              NEW
            </div>
          )}
        </>
      }
    </div>
  )
}

export const ContentBlock = ({ title, description, children }: any) => {
  return (
    <div className="p-8 rounded-lg border border-border h-full flex flex-col shadow gap-4">
      <Typography>
        <h2 className="mb-3">{title}</h2>
        <p className="leading-relaxed">{description}</p>
      </Typography>
      {children}
    </div>
  )
}
