import { CheckIcon, ReloadIcon } from "@radix-ui/react-icons"
import { useFetcher } from "@remix-run/react"
import { useEffect, useState } from "react"
import { loader } from "~/routes/_app+/bookmarks"
import { Button } from "~/components/ui/button"
import { cn } from "~/utils/shadecn"

export function Likes({
  likeCount,
}: {
  likeCount: number
}) {
  return (
    <div className="flex whitespace-nowrap justify-start items-center gap-1 sm:gap-2 py-1 rounded-lg">
      <div className="flex justify-start items-center gap-2">
        <p className="text-xl font-bold">{likeCount}</p>
        <p className="text-lg">Yeses</p>
      </div>
      <div className="flex justify-start items-center gap-2">
        <p className="text-sm">
          &nbsp;<span className="text-muted-foreground">|</span>&nbsp;&nbsp; Enjoyed this video?
        </p>
      </div>
    </div>
  )
}

export function LikeButton({
  videoID,
  likeID,
  setLikeID,
  setLikeCount,
  likeCount,
  disabled,
}: {
  videoID: number
  likeID: number | undefined
  setLikeID: (id: number | undefined) => void
  setLikeCount: (count: number) => void
  likeCount: number
  disabled?: boolean
}) {
  const fetcher = useFetcher()
  const [submitting, setSubmitting] = useState(false)
  const [like, setLike] = useState(likeID)

  useEffect(() => {
    setSubmitting(fetcher.state == "submitting")
    if (fetcher.data) {
      setLike(fetcher.data.like?.LikeID)
      setLikeCount(fetcher.data.likeCount)
    }
  }, [fetcher.state, fetcher.data])

  return (
    <fetcher.Form action={`/videos/likes?videoID=${videoID}`} method={like ? "delete" : "post"}>
      <Button
        size="sm"
        variant="secondary"
        className={cn("group p-2 h-9", like ? "" : "hover:bg-primary")}
        type="submit"
        disabled={disabled || submitting}>
        {submitting ?
          <>
            <ReloadIcon className="inline mr-2 h-4 w-4 animate-spin" />
            Yessing...
          </>
        : <>
            {like ?
              <>
                <input type="hidden" name="likeID" value={like} />
                <input type="hidden" name="videoID" value={videoID} />
                <span className="text-xl text-primary flex items-center">
                      <CheckIcon className="inline" /> &nbsp;&#128525;
                    </span>{" "}
                    &nbsp;&nbsp;Yes!Yes!Yes!
              </>
            : <>
                <span className="text-xl grayscale group-hover:grayscale-0">&#128525;</span>&nbsp; Yes?Yes?Yes?
              </>
            }
          </>
        }
      </Button>
    </fetcher.Form>
  )
}
