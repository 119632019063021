import { BookmarkIcon, BookmarkFilledIcon, ReloadIcon } from "@radix-ui/react-icons"
import { useFetcher } from "@remix-run/react"
import { useEffect, useState } from "react"
import { loader } from "~/routes/_app+/bookmarks"
import { Button } from "~/components/ui/button"

export function BookmarkButton({
  className,
  videoID,
  bookmarkID,
  variant,
}: {
  className?: string
  videoID: number
  bookmarkID: number | undefined
  variant?: string
}) {
  const fetcher = useFetcher<typeof loader>()
  const [submitting, setSubmitting] = useState(false)
  const [bookmark, setBookmark] = useState(bookmarkID)

  useEffect(() => setBookmark(bookmarkID), [bookmarkID])

  useEffect(() => {
    setSubmitting(fetcher.state == "submitting")
    if (fetcher.data) {
      setBookmark(fetcher.data.bookmark?.BookmarkID)
    }
  }, [fetcher.state])

  return (
    <fetcher.Form className={className} action={`/bookmarks?videoID=${videoID}`} method={bookmark ? "delete" : "post"}>
      <Button
        variant={variant || "outline"}
        className="border-foreground/30 p-2 h-9"
        type="submit"
        disabled={submitting}>
        {submitting ?
          <>
            <ReloadIcon className="inline mr-2 h-4 w-4 animate-spin" />
            Bookmarking...
          </>
        : <>
            {bookmark ?
              <>
                <input type="hidden" name="bookmarkID" value={bookmark} />
                <BookmarkFilledIcon className="mr-2 h-4 w-4" /> Bookmarked
              </>
            : <>
                <BookmarkIcon className="mr-2 h-4 w-4" /> Bookmark
              </>
            }
          </>
        }
      </Button>
    </fetcher.Form>
  )
}
